import React, { useState } from "react";
import { Search } from "react-feather";

function Status(props) {
  const [jobNo, setJobNo] = useState("");
  const [repairReference, setRepairReference] = useState("");

  //
  function getJob(e) {
    if (jobNo > "") {
      props.history.push(`/policy/${jobNo}`);
    }
  }

  function getRepair() {
    if (repairReference > "") {
      props.history.push(`/reference/${repairReference}`);
    }
  }

  return (
    <div className="px-4 py-5">
      <div className="row col-12 center">
        <div className="center text-center">
          <h1>Find A Job</h1>
        </div>
        <hr className="" />
      </div>
      <div>
        <div>
          <h2>Find A Job</h2>
        </div>
        <div className="">
          <form className="form-inline col-12 col-md-3 my-2 my-lg-0">
            <label htmlFor="search-job">Search by Job Number</label>
            <div className="input-group">
              <input
                id="search-job"
                name="search-job"
                className="form-control mr-sm-2"
                placeholder="By Job Number"
                value={jobNo}
                required
                onChange={(e) => {
                  setJobNo(e.target.value);
                }}
              />
              <button
                className="btn btn-outline-success my-2 my-sm-0"
                type="submit"
                aria-label="Search"
                aria-labelledby="search-job"
                onClick={getJob}
              >
                <Search />
              </button>
            </div>
          </form>
          <form className="form-inline col-12 col-md-3 my-2 my-lg-0">
            <label htmlFor="search-reference">Search By Repair Reference</label>
            <div className="input-group">
              <input
                id="search-reference"
                name="search-reference"
                className="form-control mr-sm-2"
                placeholder="By Repair Reference"
                value={repairReference}
                required
                onChange={(e) => {
                  setRepairReference(e.target.value);
                }}
              />
              <button
                className="btn btn-outline-success my-2 my-sm-0"
                type="submit"
                aria-label="Search"
                aria-labelledby="search-reference"
                onClick={getRepair}
              >
                <Search />
              </button>
            </div>
          </form>
        </div>
        <div className="mt-4">
          <h3>Job Details</h3>
        </div>
      </div>
    </div>
  );
}

export default Status;
