import React, { useEffect, useState, Fragment } from "react";
import Popup from "../_components/Popup";
const ROOT_API = process.env.REACT_APP_API_URL;

function Admin(props) {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isErrorUser, setIsErrorUser] = useState(false);
  const [errorMsgAddUser, setErrorMsgAddUser] = useState("");
  const [isErrorReset, setIsErrorReset] = useState(false);
  const [errorMsgReset, setErrorMsgResetUser] = useState("");
  const [isLoadingUsers, setIsLoading] = useState(true);
  const [isLoadingJobs, setIsLoadingJobs] = useState(true);
  const [resetPopup, setResetPopup] = useState(false);
  const [userPopup, setUserPopup] = useState(false);
  const [data, setData] = useState([]);
  const [dataJobs, setDataJobs] = useState([]);

  const [passwordReset, setPasswordReset] = useState("");

  const [id, setID] = useState("");
  const [user, setUser] = useState("");
  const [mail, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");

  async function ResetUser() {
    await fetch(`${ROOT_API}/user`, {
      method: "PATCH",
      body: JSON.stringify({
        password: passwordReset,
        updatePassword: true,
      }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Accept: "application/json; charset=UTF-8",
        Connection: "close",
        "session-id": JSON.parse(localStorage.getItem("session"))
      },
    })
      .then((result) => {
        if (result["error-code"] !== 200) {
          setIsErrorReset(true);
          setErrorMsgResetUser(result["error-message"]);
        } else {
          toggleResetPopup();
        }
      })
      .catch((err) => {
        setIsErrorUser(true);
        setErrorMsgAddUser("Internal Error please contact your Administrator");
      });
  }

  // Use popup for adding user
  async function AddUser() {
    await fetch(`${ROOT_API}/users`, {
      method: "POST",
      body: JSON.stringify({
        name: user,
        login: mail,
        "mail-address": mail,
        password,
        role,
      }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Accept: "application/json; charset=UTF-8",
        Connection: "close",
        "session-id": JSON.parse(localStorage.getItem("session"))
      },
    })
      .then((result) => {
        if (result.status !== 200) {
          setIsErrorUser(true);
          setErrorMsgAddUser(result["error-message"]);
        } else {
          setUserPopup(false);
          isLoadingUsers(true);
        }
      })
      .catch((err) => {
        setIsError(true);
        setErrorMsg("Internal Error please contact your Administrator");
      });
  }

  const toggleResetPopup = (button) => {
    console.log(button);
    setResetPopup(!resetPopup);
    setID(user);
  };

  const toggleUserPopup = () => {
    setUserPopup(!userPopup);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await fetch(`${ROOT_API}/users`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Accept: "application/json; charset=UTF-8",
            Connection: "close",
            "session-id": JSON.parse(localStorage.getItem("session")),
          },
        });
        const dataJSON = await data.json();
        if (dataJSON) {
          setData(dataJSON.data.users);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
        setErrorMsg(error.message);
      }
    };
    const fetchJobs = async () => {
      try {
        const data = await fetch(`${ROOT_API}/jobs`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Accept: "application/json; charset=UTF-8",
            Connection: "close",
            "session-id": JSON.parse(localStorage.getItem("session")),
          },
        });
        const dataJSON = await data.json();
        if (dataJSON) {
          setDataJobs(dataJSON.data.jobs);
          setIsLoadingJobs(false);
        }
      } catch (error) {
        setIsLoadingJobs(false);
        setIsError(true);
        setErrorMsg(error.message);
      }
    };

    fetchUsers();
    fetchJobs();
  }, [isLoadingUsers, isLoadingJobs]);

  return (
    <div className="px-4 py-5">
      <div className="row col-12 center">
        <div className="center text-center">
          <h1>Admin Panel</h1>
        </div>
        <hr className="" />
      </div>
      <div>
        {isLoadingUsers ? (
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Reset Password</th>
                </tr>
              </thead>
              <tbody className="placeholder-table">
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Reset Password</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => {
                  return (
                    <tr>
                      <td>{item.name}</td>
                      <td>{item["mail-address"]}</td>
                      <td>{item.role}</td>
                      {/* <td><button value={item["user-id"]} name={item["user-id"]} className="btn btn-secondary" onClick={() => toggleResetPopup(item["user-id"])}>Reset Password</button></td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

        <div className="mb-3" />

        <button className="btn btn-primary" onClick={setUserPopup}>
          Add User
        </button>

        {isLoadingJobs ? (
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>Repair Reference</th>
                  <th>Policy No.</th>
                  <th>Date Added</th>
                  <th>Product Type</th>
                  <th>Job Status</th>
                  <th>Expected Dispatch Date</th>
                  <th>Additonal Info</th>
                </tr>
              </thead>
              <tbody className="placeholder-table">
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>Repair Reference</th>
                  <th>Policy No.</th>
                  <th>Date Added</th>
                  <th>Product Type</th>
                  <th>Job Status</th>
                  <th>Expected Dispatch Date</th>
                  <th>Additonal Info</th>
                </tr>
              </thead>
              <tbody>
                {dataJobs.map((item) => {
                  return (
                    <tr>
                      <td>{item["repair-reference"]}</td>
                      <td>{item["policy-no"]}</td>
                      <td>{item["file-date"]}</td>
                      <td>{item["product-type"]}</td>
                      <td>{item["job-status"]}</td>
                      <td>{item["status-date"]}</td>
                      <td>{item["spare-text3"]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

        {isError && <span className="error">{errorMsg}</span>}

        {resetPopup && (
          <Popup
            content={
              <>
                <b>Reset Password for {id}</b>
                <form>
                  <label>Enter Default Password</label>
                  <input
                    className="form-control"
                    type="text"
                    value={passwordReset}
                    onChange={(e) => {
                      setPasswordReset(e.target.value);
                    }}
                  ></input>
                  <button className="btn btn-primary" onClick={ResetUser}>
                    Add User
                  </button>
                  {isErrorReset && (
                    <span className="error">{errorMsgReset}</span>
                  )}
                </form>
              </>
            }
            handleClose={toggleResetPopup}
          />
        )}

        {userPopup && (
          <Popup
            content={
              <div className="container">
                <form>
                  <h3>Add New User</h3>
                  <div className="col-12 col-md-7">
                    <label htmlFor="name">Name (*)</label>
                    <input
                      name="name"
                      id="name"
                      className="form-control"
                      type="text"
                      value={user}
                      onChange={(e) => {
                        setUser(e.target.value);
                      }}
                      required
                      aria-required
                      enterKeyHint="next"
                    />
                  </div>
                  <div className="col-12 col-md-7">
                    <label htmlFor="email">Email (*)</label>
                    <input
                      name="email"
                      id="email"
                      className="form-control"
                      type="email"
                      value={mail}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      placeholder="email"
                      required
                      aria-required
                      enterKeyHint="next"
                    />
                  </div>
                  <div className="col-12 col-md-7">
                    <label htmlFor="new-password">Password (*)</label>
                    <input
                      name="new-password"
                      id="new-password"
                      className="form-control"
                      type="password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      placeholder=""
                      required
                      aria-required
                      enterKeyHint="next"
                    />
                  </div>
                  <div className="col-12 col-md-7 mb-3">
                    <label htmlFor="user-role">User Role (*)</label>
                    <select
                      name="user-role"
                      id="user-role"
                      className="form-control"
                      value={role}
                      onChange={(e) => {
                        setRole(e.target.value);
                      }}
                      required
                      aria-required
                    >
                      <option defaultValue value="">
                        Select Role
                      </option>
                      <option value="0">User</option>
                      <option value="1">Admin</option>
                    </select>
                  </div>
                </form>
                <button className="btn btn-primary" onClick={AddUser}>
                  Add User
                </button>
                {isErrorUser && (
                  <span className="error">{errorMsgAddUser}</span>
                )}
              </div>
            }
            handleClose={toggleResetPopup}
          />
        )}

        {userPopup && (
          <Popup
            content={
              <div className="container">
                <form>
                  <h3>Add New User</h3>
                  <div className="col-12 col-md-7">
                    <label>Name (*)</label>
                    <input
                      className="form-control"
                      type="email"
                      value={user}
                      onChange={(e) => {
                        setUser(e.target.value);
                      }}
                      placeholder="email"
                    />
                  </div>
                  <div className="col-12 col-md-7">
                    <label>Email (*)</label>
                    <input
                      className="form-control"
                      type="email"
                      value={mail}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      placeholder="email"
                    />
                  </div>
                  <div className="col-12 col-md-7">
                    <label>Password (*)</label>
                    <input
                      className="form-control"
                      type="password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      placeholder=""
                    />
                  </div>
                  <div className="col-12 col-md-7">
                    <label>User Role (*)</label>
                    <select
                      className="form-control"
                      type="text"
                      value={role}
                      onChange={(e) => {
                        setRole(e.target.value);
                      }}
                    >
                      <option defaultValue value="">
                        Select Role
                      </option>
                      <option value="0">User</option>
                      <option value="1">Admin</option>
                    </select>
                  </div>
                  <div className="mb-3" />
                </form>
                <button className="btn btn-primary" onClick={AddUser}>
                  Add User
                </button>
                {isErrorUser && (
                  <span className="error">{errorMsgAddUser}</span>
                )}
              </div>
            }
            handleClose={toggleUserPopup}
          />
        )}
      </div>
    </div>
  );
}
export default Admin;
