import React from "react";
import Logo from "../img/NewLogo.jpg";

function Home() {
  return (
    <div className="px-4 py-5">
      <div className="row col-12 center">
        <div className="center text-center">
          <h1>Superbase Repair Portal</h1>
        </div>
        <hr className="" />
        <div className="col-md-4 center text-center">
          <img className="welcome-logo" alt="Big Company Logo" src={Logo}></img>
        </div>
      </div>
      <div>
        <div className="py-5">
          <h2>Good Morning, Nikolaus</h2>
        </div>
      </div>
    </div>
  );
}

export default Home;
