import React from "react";

const API_URL = process.env.REACT_APP_API_URL;

const Submit = (props) => {
  const { data, back, handleChange } = props;

  const submitJob = () => {
    fetch(`${API_URL}/jobs`, {
      method: "POST",
      body: JSON.stringify({
        warranty: data.warranty,
        "product-type": data.productType,
        manufacturer: data.manufacturer,
        "last-name": data.lastName,
        email: data.email,
        telephone: data.phoneNo,
        address: {
          address1: data.address.addressLine1,
          address2: data.address.addressLine2,
          town: data.address.city,
          "post-code": data.address.postCode
        },
        "return-address": {
          address1: data.returnAddress.addressLine1,
          address2: data.returnAddress.addressLine2,
          town: data.returnAddress.city,
          "post-code": data.returnAddress.postCode
        },
        model: data.model,
        "serial-no": data.serialNo,
        retailer: data.retailer,
        "purchase-date": data.dop,
        "fault-details": data.fault,
        fault: data.faultType
      }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Accept: "application/json; charset=UTF-8",
        Connection: "close",
      },
    }).then((result) => {
      if (result.status === 201) {
        return result.json().then((json) => {
          // setMessagePopup(
          //   "Job Number: " +
          //   result["repairReference"] +
          //   " has been submitted"
          // );
          // LOG OTHER THINGS
        });
      } else {
      }
    });
  };

  return (
    <div>
      <h2>Summary</h2>
      <div className="row">
        <p>
          <strong>Full Name: </strong>
          {data.firstName + " " + data.lastName}
        </p>
        <p>
          <strong>Email: </strong>
          {data.email}
        </p>
        <p>
          <strong>Telephone No: </strong>
          {data.phoneNo}
        </p>
      </div>
      <div className="row">
        <h3>Address</h3>
        <p>
          <strong>Address Line 1: </strong>
          {data.address.addressLine1}
        </p>
        <p>
          <strong>Address Line 2: </strong>
          {data.address.addressLine2}
        </p>
        <p>
          <strong>Town / City: </strong>
          {data.address.city}
        </p>
        <p>
          <strong>Post Code: </strong>
          {data.address.postCode}
        </p>
      </div>
      {data.returnAddress.addressLine1 !== "" ? (
        <div className="row">
          <h3>Return Address</h3>
          <p>
            <strong>Address Line 1: </strong>
            {data.returnAddress.addressLine1}
          </p>
          <p>
            <strong>Address Line 2: </strong>
            {data.returnAddress.addressLine2}
          </p>
          <p>
            <strong>Town / City: </strong>
            {data.returnAddress.city}
          </p>
          <p>
            <strong>Post Code: </strong>
            {data.returnAddress.postCode}
          </p>
        </div>
      ) : (
        <></>
      )}

      <div className="row">
        <h3>Product Details</h3>
        <p>
          <strong>Manufacturer: </strong>
          {data.manufacturer}
        </p>
        <p>
          <strong>Model: </strong>
          {data.model}
        </p>
        <p>
          <strong>Serial No: </strong>
          {data.serialNo}
        </p>
        <p>
          <strong>Retailer: </strong>
          {data.phoneNo}
        </p>
        <p>
          <strong>Date of Purchase: </strong>
          {data.dop}
        </p>
      </div>

      <div className="row">
        <h3>Fault Details</h3>
        <p>
          <strong>Fault Type: </strong>
          {data.faultType}
        </p>
        <p>
          <strong>Fault Description: </strong>
          {data.fault}
        </p>
      </div>
      <button className="col-12 col-sm-2 mb-3" onClick={submitJob}>
        Submit
      </button>
      <button className="col-12 col-sm-2 mb-3" onClick={back}>
        previous{" "}
      </button>
    </div>
  );
};
export default Submit;
